import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxAdditionalInfo(props) {
    const classes = useStyles();
    return(
        <div>   
            <div className={classes.sidebarSectionTitle2}>Additional information</div>
            {
                props.data.map((item, index) => {
                    return <div key={index.toString()} className={classes.sidebarFieldValue}>
                                {item.field}: {item.value}
                            </div>
                })
            }   
        </div>
    )
}