import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxTickets(props) {
    
  const classes = useStyles();
  const item = props.data;
  
  return(
    <div>
      <div className={classes.sidebarSectionTitle2}>Tickets</div>                
      <div className={classes.sidebarFieldValue}>{item.description}</div>
      <div className={classes.sidebarFieldValue}>Quantity: {item.quantity}</div>
      <div className={classes.sidebarFieldValue}>Ticket price range: {item.min_price} - {item.max_price} {item.currency}</div>
      <div className={classes.sidebarFieldValue}>Sales start date: {item.date_from} {item.time_from}</div>
      <div className={classes.sidebarFieldValue}>Sales end date: {item.date_to} {item.time_to}</div>
    </div>
  )
}