import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxVideos(props) {
  
  const classes = useStyles();
  
  return (
    <>
      <div className={classes.sidebarSectionTitle2}>Videos</div>     
      {
          props.data.map((item, index) => {
          return (
            <div style={{marginBottom: "10px", padding: "3px"}}>
                <a href={item.url} target="_blank" className={classes.dxLink1}>{item.url}</a>
                <div>
                  <span>{item.description}</span>
                </div>
            </div>
          )})
      }
    </>
)}
