import React, { useEffect, useState   } from 'react'
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Header from './layout/Header'
import ProductList from './pages/ProductList'
import ProductDetails from './pages/ProductDetails'

export default function App() {
  
  const [language, setLanguage] = useState("hr");

  return (
    <Router>
      <div>
        <CssBaseline />
        <Grid style={{borderBottom: "solid 1px #e0e0e0"}}>
          <Container fixed style={{textAlign: 'left', padding: "10px", paddingLeft: "15px"}}>
              <Header setLanguage={setLanguage} />
          </Container>
        </Grid>
        <Routes>
              <Route exact path='/' element={< ProductList Language={language}/>}></Route>
              <Route exact path='/view/:id' element={< ProductDetails Language={language} />}></Route>
        </Routes>
      </div>
    </Router>
   )
}
 