import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxWorkingHours(props) {
    
    const classes = useStyles();
    
    return(
        <div>
            <div className={classes.sidebarSectionTitle2}>Working time</div>                
            <div className={classes.sidebarFieldValue}>{props.Description}</div>
            {
                props.List.map((store, index) => 
                {
                    return store.working ? <div className={classes.sidebarFieldValue} key={index.toString()}>{store.day}: {store.from}-{store.to}</div> : null
                })
            }
        </div>
    )
}