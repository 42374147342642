import React from 'react';

export default function DxSubcategories(props) {
    
    return(
        <div>
        {
            props.List.map((subcategory, index) => {
                return(
                    <small key={index} style={{fontWeight: "normal", backgroundColor: "#ffa500", color: "white", borderRadius: "4px", marginRight: "5px", padding: "3px 5px 3px 5px"}}>
                        {subcategory}
                    </small> 
                )
            })
        }
        </div>
    )
}