import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

export default function DxImages(props) {
  return (
    <ImageList sx={{ width: "100%" }} cols={3} >
      {props.data.map((item, index) => (
        <ImageListItem key={index}>
          <img
            src={item.url}
            srcSet={item.url}
            title={item.description}
            loading="lazy"
            height={100}
          />
          <ImageListItemBar
            subtitle={item.description}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}