import React from "react";
import { createStyles, makeStyles, ThemeProvider } from '@mui/styles';

export const useStyles = makeStyles((theme: ThemeProvider) =>
  createStyles({
    grow: {
      flexGrow: 1,
      anchor: "top"
    },
    primarybackground: {
      backgroundColor: "white",
      border: "none"
    },
    title: {
      color: "#333333",
      fontSize: "1rem",
      textTransform: "none"
    },
    subheader: {
      color: "#888888",
      textTransform: "none",
      textAlign: "left"
    },
    logo: { 
        paddingRight: 20 
    },
    alignLeft: {
        textAlign: "left",
    },
    titleMargin: {
        margin: 0,
        marginBottom: "5px"
    },
    noBulletsList: {
        listStyle: "none",
        padding: "0px"
    },
    sidebarSectionTitle: {
      fontSize: "1.3rem",
      fontWeight: "600", 
      marginBottom: "8px"     
    },
    sidebarFieldName: {
      fontSize: "1.1rem",
      fontWeight: "600", 
      paddingLeft: "0px"     
    },
    sidebarFieldValue: {
      fontSize: "1rem",
      color: "#666666",
      paddingLeft: "0px",
      display: "flex" , 
      paddingTop: "3px",
      paddingBottom: "3px" 
    },
    sidebarSectionTitle2: {
      fontSize: "1.3rem",
      fontWeight: "600",      
      marginTop: "16px",
      marginBottom: "8px"  
    },
    dxLink1: {
      color: "#666",
      textDecoration: "none",
    },
    dxLink2: {
      color: "#666",
      textDecoration: "none",
      marginLeft: "5px"
    }
  })
);