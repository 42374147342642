import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxContact(props) {
  
  const item = props.data;
  const classes = useStyles();
  
  return(
    <div>
      <div className={classes.sidebarSectionTitle}>Contact</div>
      <div className={classes.sidebarFieldName}>Address</div>
      <div className={classes.sidebarFieldValue}>{item.location.address}</div>
      <div className={classes.sidebarFieldValue}>{item.location.part_of_city}</div>
      <div className={classes.sidebarFieldName}>Phone</div>
      <div className={classes.sidebarFieldValue}>{item.contact.phone}</div>
      <div className={classes.sidebarFieldValue}>{item.contact.mobile_phone}</div>
      <div className={classes.sidebarFieldName}>Web</div>
      <div className={classes.sidebarFieldValue}>
        <a href={`http://${item.contact.web}`} target="_blank" className={classes.dxLink1}>{item.contact.web}</a>
      </div>      
    </div>
  )
}