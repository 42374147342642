const API_KEY = 'I4iUG/LRHnqFwudEtTUG5fg7AkE='
const API_URL = 'http://3.70.36.137:3010/api'
const LANG_HR = 'croatian'
const LANG_EN = 'english'

export function getDetails(id,lang) {

    (lang == 'hr') ? lang = LANG_HR : lang = LANG_EN
    
    const URL = `${API_URL}/properties/details?id=${id}&language=${lang}`
    
    return fetch( URL,  
        { headers: { 'x-hasura-api-key': API_KEY }})
        .then(data => data.json())
}
export function getList(category,lang) {

    const categoryNew = htmlEntities(category);
    (lang == 'hr') ? lang = LANG_HR : lang = LANG_EN
    
    const URL = `${API_URL}/properties/list?extended=false&limit=1000&page=1&category=${categoryNew}&language=${lang}`
    
    return fetch( URL,  
        { headers: { 'x-hasura-api-key': API_KEY }})
        .then(data => data.json())
}
export function getCategories(lang) {

    (lang == 'hr') ? lang = LANG_HR : lang = LANG_EN
    
    const URL = `${API_URL}/categories/list?language=${lang}`
    
    return fetch( URL,  
        { headers: { 'x-hasura-api-key': API_KEY }})
        .then(data => data.json())
}
function htmlEntities(str) {
    return String(str).replace(/&/g, '%26').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}