import React, { useEffect, useState   } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TopCategories from '../layout/TopCategories';
import { createStyles, makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import DxWorkingHours from './details/DxWorkingHours';
import DxAdditionalInfo from './details/DxAdditionalInfo';
import DxTickets from './details/DxTickets';
import DxVideos from './details/DxVideos';
import DxSocial from './details/DxSocial';
import DxTags from './details/DxTags';
import DxSubcategories from './details/DxSubcategories';
import DxImages from './details/DxImages';
import DxConntact from './details/DxConntact';
import DxGeneral from './details/DxGeneral';
import DxMapContainer from './details/DxMapContainer';
import { useNavigate } from 'react-router-dom';
import { INITIAL_VALUES } from './details/initialValues'
import { getDetails } from '../api/DxApi'

export default function Dx1(props) {

  const [category, setCategory] = useState("");
  let { id } = useParams();
  const [item, setItem] = useState(INITIAL_VALUES);
  const navigate = useNavigate();

  useEffect(() => {
      let mounted = true;
      getDetails(id, props.Language)
          .then(item => {
              if (mounted) {
                  setItem(item);
              }
          })
      return () => mounted = false;
  }, [props.Language])
  
  
  return (
    <React.Fragment>      
      <Grid style={{borderBottom: "solid 1px #e0e0e0", marginBottom: "20px"}}>
        <Container fixed style={{textAlign: 'left', padding: "10px", paddingLeft: "15px"}}>
            <TopCategories childFunction={setCategory} Language={props.Language}/>
        </Container>
      </Grid>
      <Grid style={{marginBottom: "20px"}}>
        <Container fixed>
            <Grid container spacing={0} >
              <Grid item md={11} sm={12}>
                <h2 style={{margin:"0px"}}>{item.name}</h2>
                <DxSubcategories List={item.subcategories} />
              </Grid>
              <Grid item md={1} sm={12}>
                <Button title="Go back" onClick={() => navigate('/') } variant={"outlined"} size="small">Go back</Button>
              </Grid>
            </Grid>
        </Container>
      </Grid>
      <Grid>
        <Container fixed>
          <Grid container>
            <Grid item md={8} sm={12} xs={12} style={{paddingRight: "20px"}}>
              <DxGeneral data={item} />
              <DxImages data={item.images} />
              <DxMapContainer latitude={item.location.latitude} longitude={item.location.longitude} />
              <Grid container>
                <Grid item md={7} sm={12}>
                  <DxVideos data={item.videos}/>
                </Grid>
                <Grid item md={5} sm={12}>
                  <DxAdditionalInfo data={item.additional} />
                </Grid>
              </Grid>
              <DxTags data={item.tags} />
            </Grid>
            <Grid item md={4} sm={12} xs={12} style={{backgroundColor: "#f3f3f3", padding: "20px"}}>
                <DxConntact data={item}/>
                <DxWorkingHours List={item.working_hours} Description={item.working_hours_description} />
                <DxTickets data={item.tickets} />
                <DxSocial data={item.contact}/>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </React.Fragment>
  );
}