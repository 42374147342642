// import React from 'react'
// import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

// const containerStyle = {
//   marginTop: "50px",
//   marginBottom: "50px",
//   width: '100%',
//   height: '300px'
// };


// function MyComponent(props) {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: 'AIzaSyBXUs9VY-SB8FxA5wT2XjGVDqIwu-lxU4M'
//   })

//   const center = {
//     lat: parseFloat(props.latitude),
//     lng: parseFloat(props.longitude),
//   };


//   const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds();
//     map.fitBounds(bounds);
//     setMap(map)
//   }, [])

//   const displayMarkers = () => {
//     return <Marker key={1} id={1} position={{
//       lat: parseFloat(props.latitude),
//       lng: parseFloat(props.longitude),
//    }}
//    onClick={() => console.log("You clicked me!")} />
//   }

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={14}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         {displayMarkers()}
//         <></>
//       </GoogleMap>
//   ) : <></>
// }

// export default React.memo(MyComponent)


import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  marginTop: "50px",
  marginBottom: "50px",
  width: '100%',
  height: '300px'
};

 

function MyComponent(props) {

  const center = {
    lat: parseFloat(props.latitude),
    lng: parseFloat(props.longitude),
  };

    const displayMarkers = () => {
    return <Marker key={1} id={1} position={{
      lat: parseFloat(props.latitude),
      lng: parseFloat(props.longitude),
   }}
   onClick={() => console.log("You clicked me!")} />
  }
  return (
    <LoadScript
      googleMapsApiKey='AIzaSyBXUs9VY-SB8FxA5wT2XjGVDqIwu-lxU4M'
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
      >
        { displayMarkers() }
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyComponent)