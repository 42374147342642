import React from 'react';
import {useStyles} from '../../layout/DxStyles';

export default function DxGeneral(props) {
  
  const item = props.data;
  const classes = useStyles();
  
  return(
    <div>
        <img src={item.cover_image.url} 
            title={item.cover_image.description} 
            style={{ width: "100%", height: "450px", objectFit: "cover", paddingRight: "10px"}}/>
        <div style={{fontSize: "1.1rem", fontWeight: "600", marginTop: "20px"}}>{item.short_description}</div>
        <div dangerouslySetInnerHTML={{__html: item.description}} />
    </div>
  )
}

