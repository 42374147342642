import React, { useEffect, useState   } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { getCategories} from '../api/DxApi'

export default function TopCategories(props) {

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let mounted = true;
        getCategories(props.Language)
            .then(item => {
                if (mounted) {
                    setCategories(item);
                }
            })
        return () => mounted = false;
    }, [props.Language])

  return (
    <React.Fragment>
      <CssBaseline />

            <Box
            sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        typography: 'body1',
        '& > :not(style) + :not(style)': {
          ml: 2,
        },
      }}
        >
        {categories.map((item, index) => (
            <Link href="#" underline="none" key={index} style={{fontSize: "14px", color: "#888"}} 
                onClick={() => { props.childFunction(item.name);  }}>
             {item.name}
            </Link>
        ))}
            </Box>
    </React.Fragment>
  );
}