export const INITIAL_VALUES = {
    name: '',
    short_description: '',
    category: '',
    subcategories: [],
    description: '',
    location: {
        address: '',
        part_of_city: '',
        city: '',
        country: '',
        longitude: "14.4712157",
        latitude: "45.3140771"
    },
    contact: {
        contact_person: "",
        email: "",
        mobile_phone: "",
        phone: "",
        web: "",
        facebook: "",
        instagram: "",
        youtube: "",
        twitter: ""
    },
    working_hours: [],
    cover_image :
        {
          url: '',
          description: '',
        },
    images : [],
    additional: [],
    videos:[],
    tags: [],
    tickets: {
        description: "",
        quantity: "",
        min_price: "",
        max_price: "",
        currency: "",
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: ""
    },
  }