import React, { useEffect, useState   } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createStyles, makeStyles } from '@mui/styles';
import TopCategories from '../layout/TopCategories';
import DxListGrid from './list/DxListGrid';

export default function ProductList(props) {
  
  const [category, setCategory] = useState("");

  return (
    <React.Fragment>
      <Grid style={{borderBottom: "solid 1px #e0e0e0", marginBottom: "20px"}}>
        <Container fixed style={{textAlign: 'left', padding: "10px", paddingLeft: "15px"}}>
            <TopCategories childFunction={setCategory} Language={props.Language}/>
        </Container>
      </Grid>
      <Grid style={{marginBottom: "20px"}}>
        <Container fixed style={{textAlign: "center"}}>  
            <h2>{category == '' ? "All categories" : category}</h2>
        </Container>
      </Grid>
      <Grid style={{backgroundColor: "#f3f3f3", paddingBottom: "20px", marginTop: "35px"}}>
        <Container fixed>
                <DxListGrid Category={category} Language={props.Language}/>
        </Container>
      </Grid>
    </React.Fragment>
  );
}