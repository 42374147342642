import React, { useEffect, useState   } from 'react';
import {
    Grid,
} from '@mui/material'
import DxItem from './DxItem'
import Pagination from '@mui/material/Pagination';
import { getList } from '../../api/DxApi'

function inPage(index, page, pageSize) {
    const from = (page-1) * pageSize;
    const to =  page * pageSize
    return (index >= from && index < to) ? true : false
}
export default function DxListGrid(props) {

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const pageSize = 16;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    
    let count = parseInt(total / pageSize) + 1;

    useEffect(() => {
        let mounted = true;
        getList(props.Category,props.Language)
            .then(items => {
                    setItems(items);
                    setTotal(items.length);
                    setPage(1);
            })
        return () => mounted = false;
    }, [props.Category, props.Language])

    return(
        <div>         
            <Grid container spacing={{ xs: 2, md: 3 }}>
                {items.map((item, index) => (
                inPage(index, page, pageSize) ? (
                    <Grid item md={3} sm={6} xs={12} key={index}>
                        <DxItem data={item} language={"English"}/>
                    </Grid>) : null
                ))
                }
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item style={{textAlign: "center", marginTop: "30px"}} md={12}>
                    <Pagination count={count} page={page} onChange={handleChange} color="primary" style={{display: "inline-flex"}}/>    
                </Grid>
            </Grid>
            
        </div>
        )
}


