import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Logo from './images/ta.svg';
import FlagHR from './images/hr.svg';
import FlagEN from './images/gb.svg';
import {useStyles} from './DxStyles';

export default function TopNavigation(props) {
  
  const classes = useStyles();
  
  return (
    <div className={classes.grow}>
        <Toolbar disableGutters style={{minHeight: "0px"}}>
        <div className={classes.logo}>
            <img
              src={Logo}
              width={188}
            />
          </div>
          <div className={classes.grow} />
          <div>
            <IconButton aria-label="" color="inherit" onClick={() => { props.setLanguage('hr')}}>
              <img src={FlagHR} width={32} />
            </IconButton>
            <IconButton aria-label="" color="inherit" onClick={() => { props.setLanguage('en') }}>
              <img src={FlagEN} width={32} />
            </IconButton>
          </div>
        </Toolbar>
    </div>
  );
}
