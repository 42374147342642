import * as React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function DxItem(props) {
  const navigate = useNavigate();
  const data = props.data;
  console.log(data);
  return (
    <Card sx={{ maxWidth: 345 }} orientation="vertical">
      <CardActionArea onClick={() => {navigate(`/view/${data.id}`)}}>
        <CardMedia
          component="img"
          height="160"
          image={data.cover_image.thumbnail_url}
        />
        <div style={{
             margin: "0px",
             color: "white",
             padding: "3px 9px",
             fontWeight: "500",
             position: "absolute",
              left: "0px",
              top: "139px",
              zIndex: "4",
              backgroundColor: "rgb(25, 118, 210)"

        }}> {data.category}</div>
        <CardContent height="80">
            <h3 style={{marginTop: "0px", marginBottom: "8px", textAlign: "left"}}>{data.name}</h3>
            <div style={{textAlign: "left"}}>
                    {data.subcategories ? data.subcategories.map((subcategory, i) => (
                        <>
                            { subcategory ?
                                <small style={{backgroundColor: "#ff9911", color: "#ffffff", borderRadius: "3px", padding: "3px 3px 3px 3px", marginRight: "5px", textTransform: "uppercase"}}>
                                    {subcategory}
                                </small> : '' }
                                </>  
                    )) : null}
                    </div>


            <div style={{height: "44px", overflow: "hidden", textAlign: "left", marginTop: "10px"}}>
                <small className="text-small">
                {data.short_description}
                </small>
            </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}