import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import TwitterIcon from '@mui/icons-material/Twitter'
import {useStyles} from '../../layout/DxStyles'

export default function DxSocial(props) {
    
    const item = props.data;
    const classes=useStyles();
    
    return (
        <>
            <div className={classes.sidebarSectionTitle2}>Social Networks</div>
            <div className={classes.sidebarFieldValue}>
                <FacebookIcon style={{color: "#1877f2"}}/>
                <a href={`http://${item.facebook}`} target="_blank" className={classes.dxLink2}>{item.facebook}</a>
            </div>
            <div className={classes.sidebarFieldValue}>
                <InstagramIcon style={{color: "#e1306c"}}/>
                <a href={`http://${item.instagram}`} target="_blank" className={classes.dxLink2}>{item.instagram}</a>
            </div>
            <div className={classes.sidebarFieldValue}>
                <TwitterIcon style={{color: "#1da1f2"}}/>
                <a href={`http://${item.twitter}`} target="_blank" className={classes.dxLink2}>{item.twitter}</a>
            </div>
            <div className={classes.sidebarFieldValue}>
                <YouTubeIcon style={{color: "#cf0000"}}/> 
                <a href={`http://${item.youtube}`} target="_blank" className={classes.dxLink2}>{item.youtube}</a>
            </div>
        </>
  );
}