import React from 'react';
import {
    Button
} from '@mui/material';
import {useStyles} from '../../layout/DxStyles';

export default function DxTags(props) {
    
    const classes = useStyles();
    
    return(
        <div>
            <ul className={classes.noBulletsList}>
            {
                props.data.map((store, index) => 
                {
                    if(store.name !== '')
                        return ( <li key={index.toString()} style={{display: "inline", marginRight: "10px"}}>
                            <Button variant="contained" size="small">{store.name}</Button>
                        </li>)
                })
            }
            </ul>
        </div>
    )
}